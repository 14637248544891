<template>
  <div class="login">
    <h2 class="typography-h2">
      <n-html :text="$t('auth.change_email.title')" />
    </h2>
    <div v-if="!form_states.email_change_token">
      <div v-if="form_states.reset">
        <p class="typography-p-regular">
          <n-html :text="$t('auth.change_email.success')" />
        </p>
        <n-button-regular
          full-width
          @click.native="$router.push({ name: 'auth.login' })"
        >
          <n-html :text="$t('auth.change_email.success_button')" />
        </n-button-regular>
      </div>
      <div v-else>
        <p class="typography-p-regular">
          {{
            $t("auth.change_email.subtitle", {
              new_email: new_email
            })
          }}
        </p>
        <n-button-regular full-width @click.native="set_new_email">
          <n-html :text="$t('auth.change_email.button')" />
        </n-button-regular>
      </div>
    </div>
    <div v-else>
      <p class="typography-p-regular">
        <n-html :text="$t('auth.reset_password.token_error')" />
      </p>
      <n-button-regular
        full-width
        @click.native="$router.push({ name: 'auth.forgot_password' })"
      >
        <n-html :text="$t('auth.reset_password.token_error_button')" />
      </n-button-regular>
    </div>
  </div>
</template>

<script>
import validation from "../../mixins/validation";

export default {
  name: "pages.auth.change_email",
  mixin: [validation],
  data: () => ({
    email_change_token: "",
    new_email: "",
    form_states: {
      email_change_token: null,
      new_email: null,
      reset: null
    }
  }),
  computed: {
    valid() {
      return !!(this.email_change_token && this.new_email);
    }
  },
  methods: {
    set_new_email() {
      if (this.valid) {
        this.form_states.reset = null;
        this.$store
          .dispatch("user/set_email", {
            email_change_token: this.email_change_token,
            new_email: this.new_email
          })
          .then(() => {
            this.form_states.reset = true;
          })
          .catch(() => (this.form_states.reset = false));
      }
    }
  },
  watch: {},
  created() {
    if ("email_change_token" in this.$route.query) {
      this.email_change_token = this.$route.query.email_change_token;
    } else {
      this.form_states.email_change_token = true;
    }

    if ("new_email" in this.$route.query) {
      this.new_email = this.$route.query.new_email;
    } else {
      this.form_states.email_change_token = true;
    }
  }
};
</script>
